<template>
  <el-card style="min-height: 45rem">
    <el-table :data="list">
      <el-table-column prop="id" label="编号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="title" label="标题" show-overflow-tooltip></el-table-column>
      <el-table-column prop="account" label="Wi-Fi名称" show-overflow-tooltip></el-table-column>
      <el-table-column prop="mode" :formatter="formatMode"  label="类型" show-overflow-tooltip></el-table-column>
      <el-table-column prop="manager_uid"  label="关联用户" show-overflow-tooltip></el-table-column>
      <el-table-column prop="nickname"  label="昵称" show-overflow-tooltip></el-table-column>
      <el-table-column label="操作">
        <template #header>
          <el-button @click="editBox = true">添加Wi-Fi码</el-button>
        </template>
        <template v-slot="s">
          <div>
            <plugin-wifi-connect-log :code-id="s.row.id"></plugin-wifi-connect-log>
          </div>
          <div  style="margin-top: .5rem">
            <el-button @click="editGo(s.row)" size="mini" type="primary">编辑</el-button>
          </div>
          <div style="margin-top: .5rem">
            <el-button size="mini" @click="remove(s.row)" type="danger">清空为空码</el-button>
          </div>
        </template>
      </el-table-column>
      <template #append>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
    </el-table>
    <el-dialog v-loading="editLoading" title="wifi编辑" destroy-on-close :visible.sync="editBox" @close="editClose">
      <el-form ref="form" :model="editForm" label-width="80px" style="padding-right: 2rem">
        <el-form-item label="标题">
          <el-input v-model="editForm.title"></el-input>
        </el-form-item>
        <el-form-item label="Wi-Fi名称">
          <el-input v-model="editForm.account"></el-input>
        </el-form-item>
        <el-form-item label="Wi-Fi密码">
          <el-input v-model="editForm.password"></el-input>
        </el-form-item>
        <el-form-item label="类型">
          <el-radio-group @change="modeChange" v-model="editForm.mode">
            <el-radio :label="1">普通用户码</el-radio>
            <el-radio :label="2">免单会员码</el-radio>
            <el-radio :label="3">商家码</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="关联用户">
          <y-user-choose
              ref="userChoose"
              :need-normal="editForm.mode === 1"
              :need-card-member="editForm.mode === 2"
              :need-card-merchant="editForm.mode === 3"
              v-model="editForm.manager_uid"
              width-input
          ></y-user-choose>
        </el-form-item>
        <template v-if="editForm.mode === 3">
          <el-form-item label="扫码后跳转商家主页" label-width="140px">
            <el-switch v-model="editForm.jump_merchant_home"></el-switch>
          </el-form-item>
          <el-form-item label="设为商家主页Wi-Fi" label-width="140px">
            <el-switch v-model="editForm.merchant_home_wifi"></el-switch>
          </el-form-item>
        </template>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="editBox = false">取 消</el-button>
          <el-button type="primary" @click="editSubmit">确 定</el-button>
        </span>
    </el-dialog>
  </el-card>
</template>

<script>

import YUserChoose from "@/components/user/YUserChoose";
import PluginWifiConnectLog from "@/components/card/PluginWifi/PluginWifiConnectLog";
const editFormTpl = {
  title:"",
  account:"",
  password:"",
  mode:1,
  manager_uid:0,
  jump_merchant_home:false,
  merchant_home_wifi:false,
}
export default {
  components:{PluginWifiConnectLog, YUserChoose},
  name: "manage",
  data(){
    return{
      list:[],
      page:1,
      total:0,
      editBox:false,
      editForm:{...editFormTpl},
      editLoading:false,
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    modeChange(){
      this.$refs.userChoose.searchReset()
    },
    editGo(item){
      this.editForm = {...item};
      this.editBox = true;
    },
    editSubmit(){
      if (this.editForm.manager_uid <= 0)return this.$message.error("请选择关联用户");
      this.editLoading = true;
      this.$u.api.card.plugin.wifi.codeEdit(this.editForm).then(()=>{
        this.$message.success("操作成功");
        this.editBox = false;
        this.load();
      }).finally(()=>{
        this.editLoading = false;
      })
    },
    editClose(){
      this.editForm = {...editFormTpl}
    },
    formatMode(e){
      let str = "";
      switch (parseInt(e.mode)){
        case 1:
          str = "普通用户码"
          break;
        case 2:
          str = "免单会员码";
          break;
        case 3:
          str = "商家码";
          break;
      }
      return str;
    },
    remove(item){
      this.$u.api.card.plugin.wifi.codeEdit({...item,manager_uid:0}).then(()=>{
        this.$message.success("操作成功")
        this.load();
      })
    },
    pageChange(e){
      this.page = e;
      this.load();
    },
    load(){
      this.$u.api.card.plugin.wifi.codeSearch({
        page:this.page,
        need_used:true,
      }).then(res=>{
        this.list = res.list;
        this.total = res.total;
      })
    },
  },
}
</script>

<style scoped>

</style>